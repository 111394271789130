/* font declarations */

@font-face {
  font-family: "WorkSansSemi";
  font-weight: bold;
  font-style: bold;
  src: url(./assets/fonts/WorkSans-SemiBold.ttf) format("truetype");
}

/* basic styles */
main {
  margin-top: 1rem;
}

.warning {
  color: red;
}
